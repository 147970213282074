import React from 'react';

import GeneralAnchor from '../general-anchor/general-anchor.component';
import GeneralButton from '../generalButton/generalButton.component';
import FooterLinks from '../footer-links/footer-links.component';
import Modal from '../modal/modal.component';

import LINK_SCHEME from '../footer-links/footer-links.utils';

const LINKS = LINK_SCHEME.filter((link) => link.title !== 'Customer complaints');

const RerouteModal = ({ location, isOpen, closeModal, url }) => {
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div className='reroute-modal'>
        <h2>You are leaving <span className='origin'>{location && location.origin}</span></h2>
        <p>Please be aware that the external site policies will differ from our website terms and conditions, cookies
          and privacy policy. The next site will open in a new browser window or tab.</p>
        <FooterLinks links={LINKS}/>
        <div className='button-wrapper'>
          <GeneralButton onClick={closeModal}>Cancel</GeneralButton>
          <GeneralAnchor
            isRed
            href={url}
            isLink={true}
          >
            I understand, let's proceed
          </GeneralAnchor>
        </div>
      </div>
    </Modal>
  );
};

export default RerouteModal;
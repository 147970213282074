import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { MenuContext } from '../../context'

const HsbcNews = () => {
  const menuContext = useContext(MenuContext);
  return (
    <section className='hsbc-news'>
      <h2>HSBC UK Media Relations</h2>
      <p>Our Press Office does not have access to any customer details and is not able to assist with customer enquiries. If you are a broker and would like further information, please refer to our <Link to='/broker-support' inert={menuContext.isMenuOpen ? "true" : null}>Broker Support</Link> page.</p>
      <p>If you are a journalist, please email us at: <a href="mailto:UKPressOffice@hsbc.co.uk" inert={menuContext.isMenuOpen ? "true" : null}>UKPressOffice@hsbc.co.uk</a></p>
    </section>
  )
}

export default HsbcNews;
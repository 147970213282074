import React, { useState } from "react";
import AnchorList from "../anchor-list/anchor-list.component";

function LoadMoreArticles({ data, initialDataCount, dataToShow, buttonId, isLink, onClick }) {
  const [loadedData, setLoadedData] = useState(data.slice(0, initialDataCount));
  const [showButton, setShowButton] = useState(true);

  const handleLoadMore = () => {
    const newDataToDisplay = data.slice(0, loadedData.length + dataToShow);
    setLoadedData(newDataToDisplay);

    if (newDataToDisplay.length >= data.length) {
      setShowButton(false);
    }
  };

  return (
    <>
      {isLink === true ? (
        <AnchorList
          additionalClassName={"news-list"}
          anchorItems={loadedData}
          isLink={true}
          onClick={(title) => onClick(title)}
        />
      ) : (
        <AnchorList additionalClassName={"news-list"} anchorItems={loadedData} onClick={(title) => onClick(title)} />
      )}
      {showButton && (
        <div className="load-more" id={buttonId}>
          <button onClick={handleLoadMore}>Load more</button>
        </div>
      )}
    </>
  );
}

export default LoadMoreArticles;

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const Modal = ({ children, isOpen, closeModal }) => {
  const portalRoot = typeof document !== 'undefined' ? document.getElementById('modal') : null;
  const modal = useRef(null);

  useEffect(() => {
    const target = modal.current;

    if (isOpen) {
      disableBodyScroll(target);
    } else {
      enableBodyScroll(target);
    }
    return () => {
      clearAllBodyScrollLocks(target);
    }
  }, [isOpen]);

  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <div
      className={`modal-container ${isOpen ? 'modal-container--open' : ''}`}
      ref={modal}
    >
      <section className='modal'>
        <button className='modal__close' onClick={closeModal} type='button'>Close modal</button>
        {children}
      </section>
    </div>
    , portalRoot);
};

export default Modal;
const LINK_SCHEME = [
  {
    title: 'Mortgage Strategy',
    href: 'https://www.mortgagestrategy.co.uk/',
  },
  {
    title: 'Mortgage Solutions',
    href: 'https://www.mortgagesolutions.co.uk/',
  },
  {
    title: 'Bank of England',
    href: 'https://www.bankofengland.co.uk/',
  },
  {
    title: 'Council of Mortgage lenders',
    href: 'https://www.ukfinance.org.uk/',
  },
];

export default LINK_SCHEME;